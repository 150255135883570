import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/ryan.parr/Documents/Apps/sprinklr.design/src/templates/mdx-page.js";
import { FakeImage } from '../../components/Docs/ButtonImages.js';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`default`}</h1>
    <h3>{`Primary Button`}</h3>
    <FakeImage mdxType="FakeImage" />
    <h3>{`Secondary Button`}</h3>
    <FakeImage imageKey="secondary" mdxType="FakeImage" />
    <h3>{`Tertiary Button`}</h3>
    <FakeImage imageKey="tertiary" mdxType="FakeImage" />
    <h3>{`Sizes`}</h3>
    <FakeImage imageKey="sizes" mdxType="FakeImage" />
    <h3>{`Spacing`}</h3>
    <FakeImage imageKey="spacing" mdxType="FakeImage" />
    <h3>{`Usage`}</h3>
    <FakeImage imageKey="usage" mdxType="FakeImage" />
    <h1>{`icon text`}</h1>
    <FakeImage imageKey="iconText" mdxType="FakeImage" />
    <h1>{`only icon`}</h1>
    <FakeImage imageKey="icon" mdxType="FakeImage" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      